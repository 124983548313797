@media only screen and (min-width: 579px) {
  body {
    background: url("/img/3portraittablet.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #f0f0f0;
  }

  .tablet_container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .sub_container {
    scroll-snap-align: none;
    height: auto;
  }

  .second_input,
  .contact,
  .blob,
  .content .title,
  .cta,
  .logo,
  .error_two,
  .mobile_header,
  .footer_one,
  .footer_two,
  .desktop_header,
  .desktop_footer {
    display: none;
  }

  .tablet_header {
    padding: 25px 0 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }

  .tablet_header .images {
    display: flex;
    justify-content: space-around;
    width: 100vw;
    height: 23.5px;
  }

  .header_title {
    display: inline;
    margin-top: 20px;
    font-size: 28px;
  }

  .subtitle {
    display: flex;
  }

  .tablet_icons {
    display: flex;
  }

  .middle_row {
    display: flex;
    flex-direction: column;
  }

  .middle_row_images {
    display: flex;
    align-items: baseline;
  }

  .divider hr {
    background: #545454;
    border: 1px solid #545454;
    margin-top: 26px;
  }

  .subtitle p {
    line-height: 40px;
    font-size: 25px;
    text-align: center;
    padding: 0;
  }

  .teaser_text {
    text-align: center;
  }

  .teaser_text p {
    font-size: 18px;
    line-height: 21px;
  }

  .teaser_text p span {
    font-size: 18px;
    line-height: 20px;
  }

  .social_share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 7px;
  }

  .social_share img {
    margin-right: 20px;
  }

  .social_share span {
    padding-right: 10px;
    font-family: "Judson", serif;
    font-weight: bold;
    font-size: 20px;
    color: #223954;
  }

  .tablet_footer {
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
  }

  /* @media only screen and (max-height: 750px) {
    .teaser_text {
      display: none;
    }
  }

  @media only screen and (max-height: 580px) {
    .middle_row {
      display: none;
    }
  }

  @media only screen and (max-height: 410px) {
    .subtitle {
      display: none;
    }
  } */
}
